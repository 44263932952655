import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardHeader,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../Page";
import clsx from "clsx";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const BasicInformationPage = (
    {
        basicInformationData,
        basicInformationLoading,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('user_accounts.basic_information.title')}
        >
            {basicInformationLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">{t('user_accounts.basic_information.title')}</Typography>
                            <Grid container alignContent={"center"} alignItems={"center"}>
                                <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                                    <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                        <Link to={urlLinks.navigation.top.index.href}>
                                            <Typography color="textPrimary">{t('general.top')}</Typography>
                                        </Link>
                                        <Link to={urlLinks.navigation.accounts.index.href}>
                                            <Typography color="textPrimary">{t('user_accounts.title')}</Typography>
                                        </Link>
                                        <Link
                                            to={urlLinks.navigation.accounts.index.sub_links.basic_information.href}>
                                            <Typography color="textPrimary">{t('user_accounts.basic_information.title')}</Typography>
                                        </Link>
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <h3>{ t('user_accounts.basic_information.card_header_title') }</h3>
                            </Box>
                            <Divider/>
                            <Box className={clsx(classes.root)}>
                                <Grid container style={{marginTop: 10}}>
                                    {/** 申込担当者 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('user_accounts.basic_information.fields.type')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography variant="body1">{basicInformationData.office_type_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込種別 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('user_accounts.basic_information.fields.country')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography variant="body1">{basicInformationData.country}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込年月日 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('user_accounts.basic_information.fields.company_name')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography variant="body1">{basicInformationData.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込種別 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('user_accounts.basic_information.fields.my_page_account_number')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography variant="body1">{basicInformationData.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込種別 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('user_accounts.basic_information.fields.my_page_account_start_date')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography variant="body1">{basicInformationData.created_at}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Box>
                </Container>
            }
        </Page>
    )
        ;
}

export default BasicInformationPage;
